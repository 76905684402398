
$xs-screen: 0px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

.container > * {
    background-color: unset;
    border-radius: 4px;
    box-shadow: unset;
  }
  
  @media screen and (max-width: $xs-screen) {
    .container {
      width: 100% !important;
    }
    .container > * {
      border-radius: 0 !important;
    }
  }