@import "variables";

:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-gotham: Gotham;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-xs: 12px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-mini-8: 14.8px;

  /* Colors */
  --color-whitesmoke-100: #f9f9f9;
  --color-whitesmoke-200: #402626;
  --color-whitesmoke-300: #eee;
  --color-whitesmoke-400: #ddd;
  --title-color: #211d4e;
  --color-white: #fff;
  --mj-light-green: #3adebb;
  --mj-deep-green: #219b8e;
  --color-darkgray-100: #999;
  --color-turquoise: #3adebb;
  --color-lightslategray: #918fa7;
  --label-color: #5a567b;
  --color-lightgray: #f9f9f9;
  --color-red: #de4e3a;
  --color-grey: #d3d3d3;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-10xs: 3px;
  --br-8xs: 5px;

  --xs-screen: 0px;
  --sm-screen: 576px;
  --md-screen: 768px;
  --lg-screen: 992px;
  --xl-screen: 1200px;

  /* Screen Size */
  // --xs-screen: 0px;
  // --sm-screen: 600px;
  // --md-screen: 900px;
  // --lg-screen: 1200px;
  // --xl-screen: 1536px;
}

$xs-screen: 0px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}

// html {
//     font-size: 100%; /* 1rem = 16px */
// }

body {
  font-size: 1rem; /* 16px */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: $md-screen) {
  /* Assuming @screen-xs translates to 576px */
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }

  .ant-table thead > tr,
  .ant-table tbody > tr {
    > th,
    > td {
      white-space: pre;
    }

    th > span,
    td > span {
      display: block;
    }
  }
}

.ant-pro-sider-collapsed-button {
  display: none;
}

.ant-tabs-ink-bar {
  background-color: #710983 !important;
}

.ant-tabs-tab-btn {
  color: #710983 !important;
  font-size: 20px !important;
}

.ant-tabs {
  align-items: center;
}

.ant-pro-layout .ant-layout-header.ant-pro-layout-header {
  background-color: #001529;
}

.anticon-menu {
  color: #ffffff;
}

.header-dropdown {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.header-dropdown:hover {
  background-color: #252a3d;
}

.ant-input-affix-wrapper {
  border-radius: 0 !important;
}

.ant-btn {
  background-color: #710983 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
}

.ant-menu-item {
  border-radius: 0 !important;
}
